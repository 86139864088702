import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import { Watch } from "vue-property-decorator";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment, { now } from "moment";
import PaymentService from "@/services/PaymentService";
import Multiselect from "vue-multiselect";
import { IBranch } from "@/models/IBranch";
import BranchService from "@/services/BranchService";
import ClientUserService from "@/services/ClientUserService";
import { IPayment } from "@/models/IPayment";
import Invoice from "@/components/invoice/Invoice";

const storedUser = localStorage.getItem("user");

@Options({
  components: {
    AdminLayout,
    DateRangePicker,
    Multiselect,
    Invoice
  },
})
export default class PaymentModule extends Vue {
  isloaded = false;
  unPaidUsers: any = [];
  paidUsers: any = [];
  totalItems = 0;
  current_page = 1;
  dateRange: any = {};
  colorx = "";
  branches: IBranch[] = [];
  selectedBranch!: {};
  selectedUser!: {};
  users: any = [];
  isUserLoad: boolean = false;
  payment!: IPayment;
  receivedPaymentDetail:any
  isInvoice = false
  clientName = ""
  paidLoad = false

  minDate = "2021-01-01";
  endDate = "2030-12-31";
  startDate = now();
  user:any={}
  opens = "center";
  singleDatePicker = "range";
  timePicker = false;
  timePicker24Hour = false;
  showWeekNumbers = false;
  showDropdowns = true;
  autoApply = true;
  linkedCalendars = true;
  closeOnEsc = true;
  ranges = true;
  appendToBody = true;
  active = false;
  search = "";
  loading = false;
  colorAlert ='primary'
    titleAlert ='Alert'

  table = {
    direction: "DESC",
    orderBy: "id",
    page: 0,
    size: 3,
    totalElements: 0,
  };

  locale: any = {
    direction: "ltr", //direction of text
    format: "DD-MM-YYYY", //fomart of the dates displayed
    separator: " - ", //separator between the two ranges
    applyLabel: "Apply",
    cancelLabel: "Cancel",
    weekLabel: "W",
    customRangeLabel: "Custom Range",
    daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
    monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
    firstDay: 1, //ISO first day of week - see moment documenations for details
  };

  async created() {
    var result = await PaymentService.getlistofPaidUnpadidUser(
      1,
      false,
      this.current_page
    );
    if (result.items.length > 0) {
      this.unPaidUsers = result.items;
      this.totalItems = result.totalCount;
      this.branches = await BranchService.getAllBranch();
    }
    //this.unPaidUsers = result.items;
    this.isloaded = true;
    this.user = JSON.parse(localStorage.getItem("user") || "{}");
  }

  async UpaidUser() {
    var result = await PaymentService.getlistofPaidUnpadidUser(
      1,
      false,
      this.current_page
    );
    this.unPaidUsers = result.items;
    this.isloaded = true;
    this.totalItems = result.totalPages;
    var user = JSON.parse(localStorage.getItem("user") || "{}");
  }

  async paidUser()
  {
    var result = await PaymentService.getlistofPaidUnpadidUser(
      this.user.clientId,
      true,
      this.current_page
    );
    this.paidUsers = result.items;
    this.paidLoad = true
  }

  handleChangePage(page) {
    this.table.page = page - 1;
    //this.isloaded = false;
    //this.setTable(this.table.page);
  }

  async setTable(pagenumber: number) {
    this.unPaidUsers = [];
    var result = await PaymentService.getlistofPaidUnpadidUser(
      1,
      false,
      this.current_page
    );
    this.unPaidUsers = result.items;
    // this.totalItems = result.totalCount
    //console.log(this.clients)
    // this.totalItems = this.clients.length;
  }

  @Watch("unPaidUsers")
  onPropertyChanged(value: string, oldValue: string) {
    this.isloaded = true;
  }

  @Watch("current_page")
  async onPropertyCurrentPageChanged(value: string, oldValue: string) {
    //this.isloaded = false
    await this.setTable(this.current_page);
    //console.log(this.current_page)
  }

  async handleSearch(searching) {
    if (searching.length > 3) {
      this.search = searching;
      var result = await PaymentService.getlistofPaidUnpadidUser(
        1,
        false,
        this.current_page
      );
      this.unPaidUsers = result.items;
    } else if (searching.length == 0) {
      var result = await PaymentService.getlistofPaidUnpadidUser(
        1,
        false,
        this.current_page
      );
      this.unPaidUsers = result.items;
    }
  }

  onBack() {
    //this.setTable(1)
    this.active = !this.active;
    this.isInvoice = !this.isInvoice;
  }

  onCreate() {
    this.active = !this.active;
  }

  async onChange(data: any) {
    if (data != undefined) {
      this.users = await ClientUserService.getClientUserByBranch(
        data.branchId
      );
    }
  }

  async onChangeUser(data: any) {
    if (data != undefined) {
      this.users = await ClientUserService.getClientUserByUserId(
        data.clientUserId
      );
      this.isUserLoad = true;
    }
  }

  onSubmit() {
    (this as any).$Progress.start();
    this.loading = true;

    this.payment = {
      userId: (this as any).selectedUser.clientUserId,
      amount: this.users.price,
      receivedBy: 1,
    };

    console.log(this.selectedUser)

    PaymentService.savePayment(this.payment).then(
      (data) => {
        this.loading = false;
        this.setTable(1);
        (this as any).$vs.notify({
          title: "Payment successfully submitted",
          text: data.message,
          color: "success",
          icon: "check_circle",
        });
        (this as any).$Progress.finish();
        this.receivedPaymentDetail = data.result
        this.clientName = this.users.clientName

        console.log(this.receivedPaymentDetail)
        this.openAlert()
        this.payment = {
          userId: 0,
          amount: 0,
          receivedBy: 0,
        };


      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "Payment",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }

  openAlert(){
    this.active = !this.active;
    (this as any).$vs.dialog({
      color:this.colorAlert,
      type:'confirm',
      title: `Print Invoice`,
      text: 'Do you want to print receipt?',
      accept:this.acceptAlert,
    })
  }

  acceptAlert(){
    this.isInvoice = !this.isInvoice;
    this.active = !this.active;
  }

  openPirntModel()
  {
      window.print()
  }
}
