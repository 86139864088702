import Vue from 'vue'
import Options from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
    name: 'InvoiceComponent',
})
export default class Invoice extends Vue {

    @Prop() readonly invoiceData!: any

    openPirntModel()
    {
        window.print()
    }
}