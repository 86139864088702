import { IResponse } from "@/models/IClient";
import { IPackage } from "@/models/IPackage";
import { IPageResponse, Result } from "@/models/IPageWiseResponse";
import { IPayment } from "@/models/IPayment";
import axios from "axios";
import auhtHeader from "./auth-header";
import getBaseURL from "./Base";

let base = getBaseURL();

const API_URL = base.baseURL;
const userId = base.userId;
const clientId = base.clientId;

class PaymentService {
  payment!: any;
  lstPayment: any = [];
  responsedata: any;

  savePayment(model: IPayment): Promise<IResponse> {

    model.receivedBy = userId

    this.responsedata = axios
      .post<IResponse>(API_URL + "ClientUser/savepayment", model, {
        headers: auhtHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getlistofPaidUnpadidUser(
    branchId: number,
    isPaid: boolean,
    pageIndex: number
  ): Result {
    this.responsedata = axios
      .get<IPageResponse>(
        API_URL +
          "ClientUser/getpaymentdetailsbydate/" +
          clientId +
          "/" +
          branchId +
          "/" +
          pageIndex +
          "?isPaid=" +
          isPaid,
        { headers: auhtHeader() }
      )
      .then((response) => {
        const results = response.data.result;
        return results;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getPackageById(packageId: number): Promise<IPackage> {
    this.responsedata = axios
      .get<IResponse>(API_URL + "Admin/GetPackageById?packageId=" + packageId, {
        headers: auhtHeader(),
      })
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
}

export default new PaymentService();
